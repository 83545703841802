<script>
    import { createEventDispatcher } from 'svelte';

    export let ctaOnly = false;
    export let allClickable = false;

    let dispatch = createEventDispatcher()
</script>

{#if allClickable}
    <a href='/buildings/add-new' on:click={() => dispatch('addBuildingRedirect')}>
        <section class='{$$props.class} flex flex-col md:flex-row w-full gap-1'>
            {#if !ctaOnly}<span class='text-gray-500'>Don't see yours?</span>{/if}
            <span class='text-gray-500'><span class='underline underline-offset-4'>
                        Add a new building</span> to CondoGenius</span>
        </section>
    </a>
{:else}
    <section class='{$$props.class} flex flex-col md:flex-row w-full gap-1'>
        {#if !ctaOnly}<span class='text-gray-500'>Don't see yours?</span>{/if}
        <span class='text-gray-500'><a href='/buildings/add-new' on:click={() => dispatch('addBuildingRedirect')} class='underline underline-offset-4'>
                        Add a new building</a> to CondoGenius</span>
    </section>
{/if}
